import { v4 as uuidV4 } from 'uuid';
import { BudgetDefinition, ItemGroup } from '../../../types';

export const useGroups = () => {
  const updateGroup = (
    budget: BudgetDefinition,
    groupId: string,
    updates: Partial<ItemGroup>
  ): BudgetDefinition => {
    const groupIndexToUpdate = budget.itemGroups.findIndex(
      group => group.id === groupId
    );

    if (groupIndexToUpdate == -1) {
      throw new Error(
        `Failed to update group: ${groupId}, item group does not exist.`
      );
    }

    const updatedItemGroups = [...budget.itemGroups];
    updatedItemGroups[groupIndexToUpdate] = {
      ...budget.itemGroups[groupIndexToUpdate],
      ...updates,
    };

    return {
      ...budget,
      itemGroups: updatedItemGroups,
    };
  };

  const createGroup = (
    budget: BudgetDefinition,
    group: Omit<ItemGroup, 'id' | 'items'>
  ): BudgetDefinition => {
    return {
      ...budget,
      itemGroups: [
        ...budget.itemGroups,
        {
          ...group,
          id: uuidV4(),
          items: [],
        },
      ],
    };
  };

  const deleteGroup = (
    budget: BudgetDefinition,
    groupId: string
  ): BudgetDefinition => {
    return {
      ...budget,
      itemGroups: budget.itemGroups.filter(group => group.id !== groupId),
    };
  };

  return {
    createGroup,
    updateGroup,
    deleteGroup,
  };
};
