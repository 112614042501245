import { Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingSpinner } from '../LoadingSpinner';
import { useAuth } from './hooks/useAuth';

export const VerificationForm = () => {
  const navigate = useNavigate();
  const { email } = useParams();
  const { user, confirmSignUp } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      const formData = new FormData(event.target);

      setErrorMessage('');
      setIsLoading(true);
      await confirmSignUp({
        email: email || (formData.get('email') as string),
        code: formData.get('code') as string,
      });
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(`Verification failed: ${error.code}`);
    }
  };

  useEffect(() => {
    const cleanUp = Hub.listen('auth', ({ payload }) => {
      const { event } = payload;

      if (event === 'autoSignIn') {
        if (payload.data != null) {
          setIsLoading(false);
          navigate('/budgets');
        }
      } else if (event === 'autoSignIn_failure') {
        setIsLoading(false);
        navigate('/login');
      }
    });

    return () => cleanUp();
  }, [user, navigate]);

  return (
    <div className="flex flex-col w-full items-center justify-center pt-12 pb-24 px-6">
      <div className="flex flex-col items-center">
        <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="mt-2">
              <span>Please check your email for a verification code.</span>
              <form onSubmit={handleSubmit}>
                <div className="mt-4 flex flex-col mb-6">
                  <div className="relative">
                    <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                      </svg>
                    </div>

                    <input
                      id="email"
                      type="email"
                      name="email"
                      className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                      placeholder="type your email address here"
                      defaultValue={email}
                      disabled={email != null}
                    />
                  </div>
                </div>
                <div className="flex flex-col mb-6">
                  <div className="relative">
                    <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                      <span>
                        <svg
                          className="h-6 w-6"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                        </svg>
                      </span>
                    </div>

                    <input
                      id="code"
                      type="code"
                      name="code"
                      className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                      placeholder="paste your verification code here"
                    />
                  </div>
                </div>

                <div className="flex w-full">
                  <button
                    type="submit"
                    className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-gray-800 hover:bg-gray-700 rounded py-2 w-full transition duration-150 ease-in"
                  >
                    <span className="mr-2">Verify</span>
                    <span>
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </span>
                  </button>
                </div>
              </form>
              <div className="flex justify-center items-center mt-4 mb-4">
                <div className="inline-flex items-center font-bold text-gray-800 hover:text-gray-700 text-xs text-center">
                  <span
                    className="text-[10px] hover:cursor-pointer" // TODO: Figure out why cursor not auto changing to pointer for this link specifically.
                    onClick={() => window.history.back()}
                  >
                    Go Back
                  </span>
                </div>
              </div>
              <span className="mt-4 text-red-500 font-bold flex justify-center">
                {errorMessage}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
