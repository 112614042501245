import { Auth } from 'aws-amplify';
import { StatusCodes } from 'http-status-codes';
import { BACKEND_URL } from '../../../config';
import { BudgetDefinition } from '../../../types';

export const useBudgets = () => {
  const getBudgets = async (): Promise<Array<BudgetDefinition>> => {
    try {
      const currentSession = await Auth.currentSession();
      const jwt = currentSession.getAccessToken().getJwtToken();
      const res = await fetch(`${BACKEND_URL}/budgets`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (res.status === StatusCodes.NO_CONTENT) {
        return [];
      }

      return res.json();
    } catch (err) {
      console.error(`Failed to get budgets: ${err}`);
    }
  };

  const getBudget = async (budgetId: number): Promise<BudgetDefinition> => {
    try {
      const currentSession = await Auth.currentSession();
      const jwt = currentSession.getAccessToken().getJwtToken();
      const res = await fetch(`${BACKEND_URL}/budget/${budgetId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      });

      return res.json();
    } catch (err) {
      console.error(`Failed to get budget: ${err}`);
    }
  };

  const createBudget = async (
    budget: Partial<BudgetDefinition>
  ): Promise<BudgetDefinition> => {
    try {
      const currentSession = await Auth.currentSession();
      const jwt = currentSession.getAccessToken().getJwtToken();

      // Random number between 111111111 and 999999999.
      const budgetId = Math.floor(
        Math.random() * (999999999 - 111111111) + 111111111
      );

      const res = await fetch(`${BACKEND_URL}/budget/${budgetId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          budgetName: budget.budgetName,
          fundContributionRate: budget.fundContributionRate,
          salarySacrifice: budget.salarySacrifice,
          hasStudentLoan: budget.hasStudentLoan,
          annualSalary: budget.annualSalary,
        }),
      });

      return res.json();
    } catch (err) {
      console.error(`Failed to create budget: ${err}`);
    }
  };

  const updateBudget = async (
    budget: Partial<BudgetDefinition>
  ): Promise<BudgetDefinition> => {
    try {
      const currentSession = await Auth.currentSession();
      const jwt = currentSession.getAccessToken().getJwtToken();

      const res = await fetch(`${BACKEND_URL}/budget/${budget.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          budgetName: budget.budgetName,
          fundContributionRate: budget.fundContributionRate,
          salarySacrifice: budget.salarySacrifice,
          hasStudentLoan: budget.hasStudentLoan,
          annualSalary: budget.annualSalary,
          itemGroups: budget.itemGroups,
        }),
      });

      return res.json();
    } catch (err) {
      console.error(`Failed to update budget: ${err}`);
    }
  };

  const deleteBudget = async (budgetId: number) => {
    try {
      const currentSession = await Auth.currentSession();
      const jwt = currentSession.getAccessToken().getJwtToken();
      const res = await fetch(`${BACKEND_URL}/budget/${budgetId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      });

      return res.json();
    } catch (err) {
      console.error(`Failed to delete budget: ${err}`);
    }
  };

  return {
    getBudgets,
    getBudget,
    createBudget,
    updateBudget,
    deleteBudget,
  };
};
