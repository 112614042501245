import { TimePeriod } from '../types';

// TODO: Test.
export const convertToAnnualFromBudgetFrequency = (
  amount: number,
  budgetFrequency: TimePeriod
) => {
  switch (budgetFrequency) {
    case TimePeriod.DAILY:
      return amount * 365;
    case TimePeriod.WEEKLY:
      return amount * 52;
    case TimePeriod.FORTNIGHTLY:
      return amount * 26;
    case TimePeriod.MONTHLY:
      return amount * 12;
    default:
      return amount; // We default everywhere to annual/yearly.
  }
};
