enum StudentLoanRepaymentThresholds {
  weekly = 439,
  fortnightly = 878,
  fourWeekly = 1756,
  monthly = 1902.33,
  yearly = 22828,
}

/**
 * Subtract annual SL repayment cap from annual salary. 12% of the result is the daily student loan repayment amount.
 *
 * @param {number} annualSalary
 * @returns {*}
 */
export const calculateStudentLoanRepayment = (annualSalary: number) => {
  const gross = annualSalary - StudentLoanRepaymentThresholds.yearly;

  return gross * 0.12;
};
