import { Outlet } from 'react-router-dom';
import { LoadingSpinner } from '../LoadingSpinner';
import { useAuth } from './hooks/useAuth';

export const ProtectedRoutes = () => {
  const { user } = useAuth();

  if (user == null) {
    return (
      <div className="flex flex-col w-full items-center justify-center pt-12 pb-24 px-6">
        <div className="flex flex-col items-center">
          <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
            <LoadingSpinner />
          </div>
        </div>
      </div>
    );
  }

  return <Outlet />;
};
