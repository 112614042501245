import { v4 as uuidV4 } from 'uuid';

import { ItemType, TimePeriod } from '../../types';
import { sortByItemType } from '../../utils/sortByItemType';

// TODO: Test.
const buildBudgetSummaryOutputGroup = (amountRemaining: number) => ({
  id: uuidV4(),
  name: "What's left?",
  items: [
    {
      id: uuidV4(),
      name: 'Remaining',
      amount: amountRemaining,
      type: amountRemaining >= 0 ? ItemType.CR : ItemType.DR,
      frequency: TimePeriod.YEARLY,
    },
  ],
});

interface BudgetSummaryProps {
  amountRemaining: number;
}

export const BudgetSummary = ({ amountRemaining }: BudgetSummaryProps) => {
  const outputGroup = buildBudgetSummaryOutputGroup(amountRemaining);

  return (
    <tbody key={outputGroup.id}>
      <tr>
        <td className="pt-8 pb-6 text-gray-900 whitespace-nowrap font-medium">
          {outputGroup?.name}
        </td>
      </tr>
      {outputGroup?.items.sort(sortByItemType).map(item => (
        <tr key={item.id} className="bg-white border-y">
          <td className="pl-10 px-6 py-4 text-gray-900 whitespace-nowrap">
            {item.name}
          </td>
          <td className="px-6 py-4 text-green-500 text-center">
            {item.type === ItemType.CR ? `$${item.amount.toFixed(2)}` : ''}
          </td>
          <td className="px-6 py-4 text-red-500 text-center">
            {item.type === ItemType.DR ? `$${item.amount.toFixed(2)}` : ''}
          </td>
        </tr>
      ))}
    </tbody>
  );
};
