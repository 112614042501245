import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './Auth/hooks/useAuth';

export const Banner = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <nav className="bg-gray-800 p-2 mt-0 w-full">
      <div className="container mx-auto flex flex-wrap items-center">
        <div className="flex w-full md:w-1/2 justify-center md:justify-start text-white font-extrabold">
          <Link
            to="/"
            className="inline-block py-2 px-4 text-white no-underline"
          >
            <span className="text-2xl pl-2">FinanceUncle</span>
          </Link>
        </div>
        {user == null ? (
          <div className="md:w-1/2 text-end">
            <Link
              to="/login"
              className="inline-block py-2 px-4 text-white no-underline"
            >
              Get Started
            </Link>
          </div>
        ) : (
          <div className="flex w-full pt-2 content-center justify-between md:w-1/2 md:justify-end">
            <ul className="list-reset flex justify-between flex-1 md:flex-none items-center">
              <li className="mr-3">
                <Link
                  to="/budgets"
                  className="inline-block py-2 px-4 text-white no-underline"
                >
                  My Budgets
                </Link>
              </li>

              <li className="mr-3">
                <Link
                  to="/calculate/pay"
                  className="inline-block py-2 px-4 text-white no-underline"
                >
                  Payout Calculator
                </Link>
              </li>

              <li className="mr-3">
                <button
                  onClick={() => navigate('/logout')}
                  className="inline-block py-2 px-4 text-white no-underline"
                >
                  Logout
                </button>
                {
                  <span className="text-gray-400 italic">
                    {
                      // @ts-ignore: https://github.com/aws-amplify/amplify-js/issues/4927
                      user?.email
                    }
                  </span>
                }
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};
