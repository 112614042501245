import { v4 as uuidV4 } from 'uuid';
import { BudgetDefinition, BudgetItem, ItemGroup } from '../../../types';

export const useItems = () => {
  const updateItem = (
    budget: BudgetDefinition,
    itemId: string,
    updates: Partial<BudgetItem>
  ): BudgetDefinition => {
    const groupIndexToUpdate = budget.itemGroups.findIndex(group =>
      group.items.some(item => item.id === itemId)
    );

    if (groupIndexToUpdate == -1) {
      throw new Error(
        `Failed to update item: ${itemId}, no group contains Item: ${itemId}.`
      );
    }

    const itemIndexToUpdate = budget.itemGroups[
      groupIndexToUpdate
    ].items.findIndex(item => item.id === itemId);

    if (itemIndexToUpdate == -1) {
      throw new Error(`Failed to update item: ${itemId}, item does not exist`);
    }

    // Create the updated budget item.
    const updatedItem: BudgetItem = {
      ...budget.itemGroups[groupIndexToUpdate].items[itemIndexToUpdate],
      ...updates,
    };

    // Create a new list of items containing the updated item.
    const updatedItems = [...budget.itemGroups[groupIndexToUpdate].items];
    updatedItems[itemIndexToUpdate] = updatedItem;

    // Create a new group containing the updated list of items.
    const updatedGroup: ItemGroup = {
      ...budget.itemGroups[groupIndexToUpdate],
      items: updatedItems,
    };

    // Create a new list of groups containing the updated group and associated item.
    const updatedGroups = [...budget.itemGroups];
    updatedGroups[groupIndexToUpdate] = updatedGroup;

    return {
      ...budget,
      itemGroups: updatedGroups,
    };
  };

  const createItem = (
    budget: BudgetDefinition,
    groupId: string,
    item: Omit<BudgetItem, 'id'>
  ): BudgetDefinition => {
    // Find group to assign the item.
    const groupIndexToUpdate = budget.itemGroups.findIndex(
      group => group.id === groupId
    );

    if (groupIndexToUpdate == -1) {
      throw new Error(
        `Failed to create item for Group: ${groupId}, group does not exist.`
      );
    }

    // Create new list of items containing the new item.
    const updatedItems = [
      ...budget.itemGroups[groupIndexToUpdate].items,
      {
        id: uuidV4(),
        ...item,
      },
    ];

    // Update the relevant group to contain the new item.
    const updatedItemGroups = [...budget.itemGroups];
    updatedItemGroups[groupIndexToUpdate] = {
      ...budget.itemGroups[groupIndexToUpdate],
      items: updatedItems,
    };

    return {
      ...budget,
      itemGroups: updatedItemGroups,
    };
  };

  const deleteItem = (
    budget: BudgetDefinition,
    itemId: string
  ): BudgetDefinition => {
    const groupIndexToUpdate = budget.itemGroups.findIndex(group =>
      group.items.some(item => item.id === itemId)
    );

    if (groupIndexToUpdate == -1) {
      throw new Error(
        `Failed to delete item: ${itemId}, item group does not exist.`
      );
    }

    const updatedBudget = {
      ...budget,
    };

    const itemIndexToUpdate = updatedBudget.itemGroups[
      groupIndexToUpdate
    ].items.findIndex(item => item.id === itemId);

    if (itemIndexToUpdate == -1) {
      throw new Error(`Failed to delete item: ${itemId}, item does not exist.`);
    }

    updatedBudget.itemGroups[groupIndexToUpdate].items.splice(
      itemIndexToUpdate,
      1
    );

    return updatedBudget;
  };

  return {
    createItem,
    updateItem,
    deleteItem,
  };
};
