import { TimePeriod } from '../types';

// TODO: Test. This one probably pretty important. I THINK this his how IRD does it. Should confirm this.
export const convertFromAnnualToBudgetFrequency = (
  total: number,
  budgetFrequency: TimePeriod
) => {
  switch (budgetFrequency) {
    case TimePeriod.DAILY:
      return total / 52 / 7;
    case TimePeriod.WEEKLY:
      return total / 52;
    case TimePeriod.FORTNIGHTLY:
      return total / 26;
    case TimePeriod.MONTHLY:
      return total / 12;
    default:
      return total; // We default everywhere to annual/yearly.
  }
};
