import { TimePeriod } from '../types';
import { calculateAcc } from './calculateAcc';
import { calculateKiwisaver } from './calculateKiwisaver';
import { calculateStudentLoanRepayment } from './calculateStudentLoanRepayment';
import { calculateTax } from './calculateTax';
import { convertFromAnnualToBudgetFrequency } from './convertFromAnnualToBudgetFrequency';

interface CalculateTakehomePayArgs {
  annualSalary: number;
  kiwisaverPercentage: number;
  salarySacrifice: boolean;
  haveStudentLoan: boolean;
  budgetFrequency?: TimePeriod;
}

export type CalculateTakehomePayResult = {
  payeAmt: number;
  accAmt: number;
  studentLoanRepaymentAmt: number;
  kiwisaverAmt: number;
  netIncome: number;
};

export const calculateTakehomePay = ({
  annualSalary,
  kiwisaverPercentage,
  salarySacrifice,
  haveStudentLoan,
  budgetFrequency,
}: CalculateTakehomePayArgs): CalculateTakehomePayResult => {
  const payeAmt = calculateTax(annualSalary);
  const accAmt = calculateAcc(annualSalary);

  const studentLoanRepaymentAmt = haveStudentLoan
    ? calculateStudentLoanRepayment(annualSalary)
    : 0;

  const kiwisaverAmt = calculateKiwisaver({
    annualSalary,
    contributionPercentage: kiwisaverPercentage,
    salarySacrifice,
  });

  const netIncome =
    annualSalary - payeAmt - accAmt - kiwisaverAmt - studentLoanRepaymentAmt;

  // TODO: Test.
  return {
    payeAmt: budgetFrequency
      ? convertFromAnnualToBudgetFrequency(payeAmt, budgetFrequency)
      : payeAmt,
    accAmt: budgetFrequency
      ? convertFromAnnualToBudgetFrequency(accAmt, budgetFrequency)
      : accAmt,
    studentLoanRepaymentAmt: budgetFrequency
      ? convertFromAnnualToBudgetFrequency(
          studentLoanRepaymentAmt,
          budgetFrequency
        )
      : studentLoanRepaymentAmt,
    kiwisaverAmt: budgetFrequency
      ? convertFromAnnualToBudgetFrequency(kiwisaverAmt, budgetFrequency)
      : kiwisaverAmt,
    netIncome: budgetFrequency
      ? convertFromAnnualToBudgetFrequency(netIncome, budgetFrequency)
      : netIncome,
  };
};
