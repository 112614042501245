import { BudgetItem, ItemType } from '../types';

/**
 * Sort CR items before DR items.
 *
 * @param {BudgetItem} a
 * @param {BudgetItem} b
 * @returns {(1 | -1 | 0)}
 */
export const sortByItemType = (a: BudgetItem, b: BudgetItem): 1 | -1 | 0 => {
  if (a.type === ItemType.CR && b.type === ItemType.DR) {
    return -1;
  }

  if (a.type === ItemType.DR && b.type === ItemType.CR) {
    return 1;
  }

  return 0;
};
