import { useNavigate } from 'react-router-dom';

export const LandingPageContent = () => {
  const navigate = useNavigate();

  return (
    <div className="container mx-auto flex flex-col md:flex-row items-center my-12 md:my-24">
      <div className="flex flex-col w-full lg:w-1/2 justify-center items-start pt-12 pb-24 px-6">
        <button
          onClick={() => navigate('/login')}
          className="bg-transparent hover:bg-gray-900 text-gray-900 hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-gray-900 hover:border-transparent"
        >
          Budgeting Sucks
        </button>
      </div>
      <div className="w-full lg:w-1/2 lg:py-6 text-center">
        <img
          src="finance-uncle.png"
          className="h-38"
          alt="Talk to Finance Uncle."
        />
      </div>
    </div>
  );
};
