import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import { BUDGET_FREQUENCIES } from '../../../config';
import { ItemType, TimePeriod } from '../../../types';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface CreateItemModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (args: {
    name: string;
    amount: number;
    frequency: TimePeriod;
    type: ItemType;
  }) => void;
}

export const CreateItemModal = ({
  open,
  onClose,
  onSubmit,
}: CreateItemModalProps) => {
  const [selectedItemType, setSelectedItemType] = useState(ItemType.DR); // Default to DR, makes sense.

  const handleSubmit = event => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const name = String(formData.get('name'));
    const frequency =
      (formData.get('frequency') as TimePeriod) || TimePeriod.FORTNIGHTLY;

    // Strip out non-numeric characters.
    const amount = Number(
      formData.get('amount').toString().split('$')[1].replace(/,/g, '')
    );

    onSubmit({ name, amount, frequency, type: selectedItemType });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="px-8 py-8 flex flex-col items-center">
        <span className="text-center font-bold">Add a new item</span>
        <div className="mt-2">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col mb-6">
              <div className="relative">
                <span>Name</span>
                <input
                  id="name"
                  type="text"
                  name="name"
                  className="mt-2 text-sm text-center sm:text-base placeholder-gray-500 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                  placeholder="Candles"
                  required
                />
              </div>
              <div className="relative mt-4">
                <span>
                  How much are you{' '}
                  {selectedItemType === ItemType.CR ? 'receiving' : 'spending'}?
                </span>
                <CurrencyInput
                  id="amount"
                  name="amount"
                  placeholder="$420.00"
                  required
                  decimalsLimit={2}
                  prefix="$"
                  className="mt-2 text-sm text-center sm:text-base placeholder-gray-500 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                />
              </div>
              <div className="relative mt-4">
                <span>
                  How often will you{' '}
                  {selectedItemType === ItemType.CR ? 'receive' : 'spend'} this
                  amount?
                </span>
                <Select
                  placeholder={TimePeriod.FORTNIGHTLY}
                  name="frequency"
                  className="w-64 mt-4 mr-4 align-middle text-center"
                  options={BUDGET_FREQUENCIES}
                />
              </div>
              <div className="relative mt-4">
                <div className="flex items-center mb-4">
                  <input
                    checked={selectedItemType === ItemType.CR}
                    onChange={() => setSelectedItemType(ItemType.CR)}
                    id="credit"
                    type="radio"
                    value={ItemType.CR}
                    name="credit"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="default-radio-1"
                    className="ml-2 text-sm font-medium text-gray-900"
                  >
                    This makes my wallet happy.
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    checked={selectedItemType === ItemType.DR}
                    onChange={() => setSelectedItemType(ItemType.DR)}
                    id="debit"
                    type="radio"
                    value={ItemType.DR}
                    name="debit"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="default-radio-2"
                    className="ml-2 text-sm font-medium text-gray-900"
                  >
                    This makes my wallet sad.
                  </label>
                </div>
              </div>
            </div>
            <div className="flex w-full">
              <button
                type="button"
                onClick={onClose}
                className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-gray-600 hover:bg-gray-700 rounded py-2 w-full transition duration-150 ease-in mr-2"
              >
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-gray-800 hover:bg-gray-700 rounded py-2 w-full transition duration-150 ease-in"
              >
                <span>Add item</span>
              </button>
            </div>
            <div className="flex justify-center items-center mt-4 mb-4"></div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};
