import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import './App.css';
import { AuthProvider } from './components/Auth/hooks/useAuth';
import { LoginForm } from './components/Auth/LoginForm';
import { Logout } from './components/Auth/Logout';
import { ProtectedRoutes } from './components/Auth/ProtectedRoutes';
import { SignUpForm } from './components/Auth/SignUpForm';
import { VerificationForm } from './components/Auth/VerificationForm';
import { Banner } from './components/Banner';
import { Budget } from './components/Budgets/Budget';
import { BudgetList } from './components/Budgets/BudgetList';
import { EditBudget } from './components/Budgets/EditBudget/EditBudget';
import { Footer } from './components/Footer';
import { LandingPageContent } from './components/LandingPageContent';
import { NotFound } from './components/NotFound';
import { PayCalculator } from './components/PayCalculator';
import './input.css';

const Layout = () => (
  <div className="bg-gray-400 font-sans leading-normal tracking-normal">
    <Banner />
    <Outlet />
    <Footer />
  </div>
);

const App = () => (
  <Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<LandingPageContent />} />
      <Route path="/signup" element={<SignUpForm />} />
      <Route path="/verify/:email" element={<VerificationForm />} />
      <Route path="/verify" element={<VerificationForm />} />
      <Route path="/login" element={<LoginForm />} />
      <Route path="/logout" element={<Logout />} />
      <Route element={<ProtectedRoutes />}>
        <Route path="/budgets" element={<BudgetList />} />
        <Route path="/budget/:budgetId" element={<Budget />} />
        <Route path="/budget/edit/:budgetId" element={<EditBudget />} />
        <Route path="/calculate/pay" element={<PayCalculator />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);
