import { Link } from 'react-router-dom';

export const NotFound = () => (
  <div>
    <h2>Page Not Found!</h2>
    <p>
      Sorry! <Link to="/">Go to the home page.</Link>
    </p>
  </div>
);
