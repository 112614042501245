export enum ItemType {
  CR = 'cr',
  DR = 'dr',
}

export enum TimePeriod {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  FORTNIGHTLY = 'Fortnightly',
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
}

export type BudgetItem = {
  id: string; // TODO: UUIDs are fine here, user won't see them.
  name: string;
  type: ItemType;
  amount: number;
  frequency: TimePeriod;
};

export type ItemGroup = {
  id: string; // TODO: UUIDs are fine here, user won't see them.
  name: string;
  items: Array<BudgetItem>;
};

export type BudgetDefinition = {
  id: number; // Numbers like Facebook profiles, not UUIDs which can be spookier for users.
  createdBy: string; // Cognito username.
  createdAt: string; // ISOString.
  updatedAt: string; // ISOString.
  budgetName: string;
  fundContributionRate: number;
  salarySacrifice: boolean;
  hasStudentLoan: boolean;
  annualSalary: number;
  itemGroups: Array<ItemGroup>;
};
