import { BudgetItem, ItemType } from '../types';
import { convertToAnnualFromBudgetFrequency } from './convertToAnnualFromBudgetFrequency';

// TODO: Test.
export const getGroupTotal = (items: Array<BudgetItem>) =>
  items.reduce((acc, item) => {
    switch (item.type) {
      case ItemType.CR:
        return (acc += convertToAnnualFromBudgetFrequency(
          item.amount,
          item.frequency
        ));

      case ItemType.DR:
        return (acc -= convertToAnnualFromBudgetFrequency(
          item.amount,
          item.frequency
        ));

      default:
        throw new Error(
          `Invalid item type: ${item.type} for Item: ${item.id}.`
        );
    }
  }, 0);
