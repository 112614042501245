import { Amplify } from 'aws-amplify';
import { TimePeriod } from './types';

// TODO: Setup prod.
const isProduction = true;

Amplify.configure({
  Auth: {
    userPoolId: isProduction
      ? 'ap-southeast-2_HSPg9Yr3n'
      : 'ap-southeast-2_657QzIgPI',
    userPoolWebClientId: isProduction
      ? '6sk8tphhkeckefb5uj4glmo1b2'
      : 'bec9p7i89t0jo6l9krp2rsk2q',
    region: 'ap-southeast-2',
    signUpVerificationMethod: 'code',
  },
});

export const BACKEND_URL = isProduction
  ? 'https://api.financeuncle.com'
  : 'https://api-staging.financeuncle.com';

export const KIWISAVER_PERCENTAGES = [
  { value: '0', label: '0%' },
  { value: '0.03', label: '3%' },
  { value: '0.04', label: '4%' },
  { value: '0.06', label: '6%' },
  { value: '0.08', label: '8%' },
  { value: '0.1', label: '10%' },
];

export const BUDGET_FREQUENCIES = [
  { value: TimePeriod.DAILY, label: 'Daily' },
  { value: TimePeriod.WEEKLY, label: 'Weekly' },
  { value: TimePeriod.FORTNIGHTLY, label: 'Fortnightly' },
  { value: TimePeriod.MONTHLY, label: 'Monthly' },
  { value: TimePeriod.YEARLY, label: 'Annually' },
];
