// TODO: Update these annually on 31st March or make it smart.
// https://www.ird.govt.nz/income-tax/income-tax-for-individuals/acc-clients-and-carers/acc-earners-levy-rates
const ACC_EARNERS_LEVY = 0.0146; // 1.46% until 31 March 2023, then is 1.53% until 31 March 2024.

// [Maximum Earnings, Maximum Levy Payable]
const ACC_EARNERS_LEVY_MAXIMUMS = [136544, 1993.54]; // Becomes [139384, 2132.57] on 31 March 2023.

export const calculateAcc = (annualSalary: number) => {
  const grossAccAmt = annualSalary * ACC_EARNERS_LEVY;

  // TODO: Consider tracking here.
  if (grossAccAmt >= ACC_EARNERS_LEVY_MAXIMUMS[1]) {
    return ACC_EARNERS_LEVY_MAXIMUMS[1];
  }

  return grossAccAmt;
};
