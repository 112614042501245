// https://www.business.govt.nz/tax-and-accounting/tax-when-you-have-employees/employer-superannuation-contribution-tax-esct/
// [floor, ceiling, rate (fraction)]
const ESCT_RATE_BRACKETS = [
  [0, 16800, 0.105],
  [16801, 57600, 0.175],
  [57601, 84000, 0.3],
  [84001, 216000, 0.33],
  [216001, 0, 0.39], // No ceiling, all income above floor is taxed at 39%.
];

// TODO: Calculate marginally.
export const calculateKiwisaver = ({
  annualSalary,
  contributionPercentage,
  salarySacrifice,
}) => {
  let esctBracket = ESCT_RATE_BRACKETS.find(
    ([floor, ceiling]) => annualSalary >= floor && annualSalary <= ceiling
  );

  // Baller AF.
  // TODO: Test.
  if (!esctBracket) {
    esctBracket = ESCT_RATE_BRACKETS[ESCT_RATE_BRACKETS.length - 1];
  }

  // TODO: salarySacrifice is meaningless. Fix bracket.
  return salarySacrifice
    ? annualSalary * contributionPercentage
    : annualSalary * contributionPercentage;
};
