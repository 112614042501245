import { useEffect, useState } from 'react';
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineFolderOpen,
} from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { BudgetDefinition } from '../../types';
import { LoadingSpinner } from '../LoadingSpinner';
import { BudgetSettingsModal } from './BudgetSettingsModal';
import { useBudgets } from './hooks/useBudgets';

export const BudgetList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { getBudgets, deleteBudget, createBudget } = useBudgets();
  const [isBudgetSettingsModalOpen, setIsBudgetSettingsModalOpen] =
    useState(false);
  const [budgetDefinitions, setBudgetDefinitions] =
    useState<Array<BudgetDefinition>>(null);

  useEffect(() => {
    async function startFetching() {
      setIsLoading(true);
      setBudgetDefinitions(null);
      const result = await getBudgets();
      if (!ignore) {
        setBudgetDefinitions(result);
        setIsLoading(false);
      }
    }

    let ignore = false;
    startFetching();
    return () => {
      ignore = true;
    };
  }, [setBudgetDefinitions]);

  const handleDeleteDefinition = async (definitionId: number) => {
    try {
      setIsLoading(true);
      await deleteBudget(definitionId);
      setBudgetDefinitions(await getBudgets());
      setIsLoading(false);
    } catch (error) {
      // TODO: Something.
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col w-full items-center justify-center pt-12 pb-24 px-6">
      <div className="flex flex-col w-full items-center">
        <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
          <span className="mb-4 text-center font-bold cursor">My Budgets</span>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div>
              {budgetDefinitions == null ||
                (budgetDefinitions?.length === 0 && (
                  <span>
                    Looks like you don't have any budgets, why not create one
                    now?
                  </span>
                ))}
              {budgetDefinitions?.map(definition => (
                <div key={definition.id} className="text-left">
                  <span
                    onClick={() => navigate(`/budget/${definition.id}`)}
                    className="cursor-pointer"
                  >
                    {definition.budgetName}
                  </span>
                  <span className="float-right">
                    <AiOutlineFolderOpen
                      data-tooltip-id="openBudget"
                      data-tooltip-content="Open this budget."
                      onClick={() => navigate(`/budget/${definition.id}`)}
                      className="inline-flex hover:cursor-pointer outline-none"
                    />
                    <AiOutlineEdit
                      data-tooltip-id="editBudget"
                      data-tooltip-content="Edit this budget."
                      onClick={() => navigate(`/budget/edit/${definition.id}`)}
                      className="inline-flex ml-2 hover:cursor-pointer outline-none"
                    />
                    <AiOutlineDelete
                      data-tooltip-id="deleteBudget"
                      data-tooltip-content="Delete this budget."
                      onClick={() => handleDeleteDefinition(definition.id)}
                      className="inline-flex ml-2 hover:cursor-pointer outline-none"
                    />
                  </span>
                  <Tooltip id="openBudget" />
                  <Tooltip id="editBudget" />
                  <Tooltip id="deleteBudget" />
                </div>
              ))}
              <button
                type="submit"
                onClick={() => setIsBudgetSettingsModalOpen(true)}
                className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-gray-800 hover:bg-gray-700 rounded py-2 w-full transition duration-150 ease-in mt-6"
              >
                Create a Budget
              </button>
            </div>
          )}
        </div>
      </div>
      <BudgetSettingsModal
        open={isBudgetSettingsModalOpen}
        onClose={() => {
          setIsBudgetSettingsModalOpen(false);
        }}
        onSubmit={async (updates: Partial<BudgetDefinition>) => {
          setIsLoading(true);
          setIsBudgetSettingsModalOpen(false);
          const newBudget = await createBudget(updates);
          setBudgetDefinitions(await getBudgets());
          setIsLoading(false);
          navigate(`/budget/${newBudget.id}`);
        }}
      />
    </div>
  );
};
