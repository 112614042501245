import { BudgetDefinition, ItemGroup, TimePeriod } from '../types';
import { calculateTakehomePay } from './calculateTakehomePay';
import { convertFromAnnualToBudgetFrequency } from './convertFromAnnualToBudgetFrequency';
import { getGroupTotal } from './getGroupTotal';

// TODO: Test.
export const calculateRemaining = (
  budget: BudgetDefinition,
  budgetFrequency: TimePeriod
) => {
  if (budget == null) {
    return 0;
  }

  const groupItemTotal = budget.itemGroups.reduce(
    (acc: number, group: ItemGroup) => {
      const total = getGroupTotal(group.items);
      return (acc += total);
    },
    0
  );

  const { netIncome } = calculateTakehomePay({
    annualSalary: budget.annualSalary,
    kiwisaverPercentage: budget.fundContributionRate,
    haveStudentLoan: budget.hasStudentLoan,
    salarySacrifice: budget.salarySacrifice,
  });

  return convertFromAnnualToBudgetFrequency(
    netIncome + groupItemTotal,
    budgetFrequency
  );
};
