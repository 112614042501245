import { useEffect, useState } from 'react';
import {
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineFileAdd,
  AiOutlineFolderAdd,
  AiOutlineSave,
} from 'react-icons/ai';
import { FiSettings } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import { BUDGET_FREQUENCIES } from '../../../config';
import {
  BudgetDefinition,
  BudgetItem,
  ItemGroup,
  ItemType,
  TimePeriod,
} from '../../../types';
import { calculateRemaining } from '../../../utils/calculateRemaining';
import { convertFromAnnualToBudgetFrequency } from '../../../utils/convertFromAnnualToBudgetFrequency';
import { convertToAnnualFromBudgetFrequency } from '../../../utils/convertToAnnualFromBudgetFrequency';
import { getGroupTotal } from '../../../utils/getGroupTotal';
import { LoadingSpinner } from '../../LoadingSpinner';
import { BaseItemGroupSummary } from '../BaseItemGroupSummary';
import { BudgetSettingsModal } from '../BudgetSettingsModal';
import { BudgetSummary } from '../BudgetSummary';
import { useBudgets } from '../hooks/useBudgets';
import { useGroups } from '../hooks/useGroups';
import { useItems } from '../hooks/useItems';
import { CreateGroupModal } from './CreateGroupModal';
import { CreateItemModal } from './CreateItemModal';
import { EditGroupModal } from './EditGroupModal';
import { EditItemModal } from './EditItemModal';

enum MoveDirection {
  UP = 'up',
  DOWN = 'down',
}

export const EditBudget = () => {
  const { deleteGroup, updateGroup, createGroup } = useGroups();
  const { deleteItem, updateItem, createItem } = useItems();
  const { getBudget, updateBudget } = useBudgets();

  const navigate = useNavigate();
  const { budgetId } = useParams();
  const [budget, setBudget] = useState<BudgetDefinition>(null);
  const [isEditGroupModalOpen, setIsEditGroupModalOpen] = useState(false);
  const [isEditItemModalOpen, setIsEditItemModalOpen] = useState(false);
  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
  const [isCreateItemModalOpen, setIsCreateItemModalOpen] = useState(false);
  const [isBudgetSettingsModalOpen, setIsBudgetSettingsModalOpen] =
    useState(false);
  const [currentGroup, setCurrentGroup] = useState<ItemGroup>(null);
  const [currentItem, setCurrentItem] = useState<BudgetItem>(null);
  const [amountRemaining, setAmountRemaining] = useState(0);
  const [budgetFrequency, setBudgetFrequency] = useState(
    TimePeriod.FORTNIGHTLY // How I get paid.
  );

  // Hack coz tooltip doesn't autohide since we're clicking to open a modal before moving mouse away.
  const [showTooltip, setShowTooltip] = useState(true);

  useEffect(() => {
    async function startFetching() {
      setBudget(null);
      const result = await getBudget(Number(budgetId));
      if (!ignore) {
        setBudget(result);
      }
    }

    let ignore = false;
    startFetching();
    return () => {
      ignore = true;
    };
  }, [budgetId]);

  useEffect(() => {
    setAmountRemaining(calculateRemaining(budget, budgetFrequency));
  }, [budget, budgetFrequency]);

  const handleDeleteGroup = async (groupId: string) => {
    const updatedBudget = deleteGroup(budget, groupId);
    setBudget(await updateBudget(updatedBudget));
  };

  const handleDeleteItem = async (itemId: string) => {
    const updatedBudget = deleteItem(budget, itemId);
    setBudget(await updateBudget(updatedBudget));
  };

  const handleMoveGroup = async (groupId: string, direction: MoveDirection) => {
    const updatedItemGroups = [...budget.itemGroups];
    const groupIndex = updatedItemGroups.findIndex(
      group => group.id === groupId
    );

    const [removed] = updatedItemGroups.splice(groupIndex, 1);

    updatedItemGroups.splice(
      direction === MoveDirection.UP ? groupIndex - 1 : groupIndex + 1,
      0,
      removed
    );

    setBudget(await updateBudget({ ...budget, itemGroups: updatedItemGroups }));
  };

  const handleMoveItem = async (itemId: string, direction: MoveDirection) => {
    const updatedItemGroups = [...budget.itemGroups];
    const groupIndex = updatedItemGroups.findIndex(group =>
      group.items.some(item => item.id === itemId)
    );

    const groupToUpdate = {
      ...updatedItemGroups[groupIndex],
    };

    const itemIndex = groupToUpdate.items.findIndex(item => item.id === itemId);

    const [removed] = groupToUpdate.items.splice(itemIndex, 1);

    groupToUpdate.items.splice(
      direction === MoveDirection.UP ? itemIndex - 1 : itemIndex + 1,
      0,
      removed
    );

    updatedItemGroups[groupIndex] = groupToUpdate;

    setBudget(await updateBudget({ ...budget, itemGroups: updatedItemGroups }));
  };

  return (
    <div className="m-auto flex flex-col max-w-screen-lg items-center justify-center pt-12 pb-24 px-6">
      <div className="flex flex-col w-full items-center">
        <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full">
          {budget == null ? (
            <LoadingSpinner />
          ) : (
            <div className="text-center">
              <span className="float-left font-bold text-4xl">
                {budget?.budgetName}
              </span>
              <div className="flex float-right pb-4">
                <Select
                  placeholder={TimePeriod.FORTNIGHTLY}
                  isSearchable={false}
                  name="budgetFrequency"
                  onChange={({ value }) => setBudgetFrequency(value)}
                  className="m-2 w-44 align-middle text-center"
                  options={BUDGET_FREQUENCIES}
                />
                <button
                  onClick={() => setIsBudgetSettingsModalOpen(true)}
                  className="m-2 float-right p-4 flex items-center align-middle focus:outline-none text-white text-sm sm:text-base bg-gray-800 hover:bg-gray-700 rounded py-2 transition duration-150 ease-in"
                >
                  <span>
                    Budget Settings
                    <FiSettings className="ml-2 inline-flex" />
                  </span>
                </button>
                <button
                  onClick={() => setIsCreateGroupModalOpen(true)}
                  className="m-2 float-right p-4 flex items-center align-middle focus:outline-none text-white text-sm sm:text-base bg-gray-800 hover:bg-gray-700 rounded py-2 transition duration-150 ease-in"
                >
                  <span>
                    {budget.itemGroups.length === 0
                      ? 'Get Started'
                      : 'Add Group'}
                    <AiOutlineFolderAdd className="ml-2 inline-flex" />
                  </span>
                </button>
                <button
                  onClick={() => navigate(`/budget/${budgetId}`)}
                  className="m-2 float-right p-4 flex items-center align-middle focus:outline-none text-white text-sm sm:text-base bg-gray-800 hover:bg-gray-700 rounded py-2 transition duration-150 ease-in"
                >
                  <span>
                    Finish Editing
                    <AiOutlineSave className="ml-2 inline-flex" />
                  </span>
                </button>
              </div>
              <div className="mt-4 w-full relative overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 text-center">
                    <tr>
                      <th scope="col" className="px-6 py-3" />
                      <th scope="col" className="px-6 py-3">
                        CR
                      </th>
                      <th scope="col" className="px-6 py-3">
                        DR
                      </th>
                      <th scope="col" className="px-6 py-3">
                        SUBTOTAL
                      </th>
                    </tr>
                  </thead>
                  <BaseItemGroupSummary
                    budget={budget}
                    budgetFrequency={budgetFrequency}
                  />
                  {budget.itemGroups.map((group, groupIndex) => {
                    const groupTotal = convertFromAnnualToBudgetFrequency(
                      getGroupTotal(group.items),
                      budgetFrequency
                    );

                    // Ensure '-' comes before '$'.
                    const formattedGroupTotal =
                      groupTotal > 0
                        ? `$${groupTotal.toFixed(2)}`
                        : `-$${Math.abs(groupTotal).toFixed(2)}`;

                    return (
                      <tbody key={group.id}>
                        <tr>
                          <td className="pt-8 pb-6 text-gray-900 whitespace-nowrap font-medium">
                            {group?.name}
                            <AiOutlineFileAdd
                              data-tooltip-id="addItem"
                              data-tooltip-content="Add a new item to this group."
                              onClick={() => {
                                setShowTooltip(false);
                                setCurrentGroup(group);
                                setIsCreateItemModalOpen(true);
                              }}
                              className="ml-2 inline-flex hover:cursor-pointer outline-none"
                              onMouseEnter={() => setShowTooltip(true)}
                            />
                            <AiOutlineEdit
                              data-tooltip-id="editGroup"
                              data-tooltip-content="Edit this group."
                              onClick={() => {
                                setShowTooltip(false);
                                setCurrentGroup(group);
                                setIsEditGroupModalOpen(true);
                              }}
                              className="ml-2 inline-flex hover:cursor-pointer outline-none"
                              onMouseEnter={() => setShowTooltip(true)}
                            />
                            <AiOutlineDelete
                              data-tooltip-id="deleteGroup"
                              data-tooltip-content="Delete this group."
                              onClick={async () => {
                                setShowTooltip(false);
                                await handleDeleteGroup(group.id);
                              }}
                              className="ml-2 inline-flex hover:cursor-pointer outline-none"
                              onMouseEnter={() => setShowTooltip(true)}
                            />
                            {groupIndex > 0 && (
                              <AiOutlineArrowUp
                                data-tooltip-id="moveGroupUp"
                                data-tooltip-content="Move this group up a level."
                                onClick={() => {
                                  setShowTooltip(false);
                                  handleMoveGroup(group.id, MoveDirection.UP);
                                }}
                                className="ml-2 inline-flex hover:cursor-pointer outline-none"
                                onMouseEnter={() => setShowTooltip(true)}
                              />
                            )}
                            {groupIndex !== budget.itemGroups.length - 1 && (
                              <AiOutlineArrowDown
                                data-tooltip-id="moveGroupDown"
                                data-tooltip-content="Move this group down a level."
                                onClick={() => {
                                  setShowTooltip(false);
                                  handleMoveGroup(group.id, MoveDirection.DOWN);
                                }}
                                className="ml-2 inline-flex hover:cursor-pointer outline-none"
                                onMouseEnter={() => setShowTooltip(true)}
                              />
                            )}
                            {showTooltip && <Tooltip id="addItem" />}
                            {showTooltip && <Tooltip id="editGroup" />}
                            {showTooltip && <Tooltip id="deleteGroup" />}
                            {showTooltip && <Tooltip id="moveGroupUp" />}
                            {showTooltip && <Tooltip id="moveGroupDown" />}
                          </td>
                        </tr>
                        {group.items.map((item: BudgetItem, itemIndex) => {
                          const { items: groupItems = [] } =
                            budget.itemGroups[groupIndex] ?? {};

                          return (
                            <tr key={item.id} className="bg-white border-y">
                              <td className="pl-10 px-6 py-4 text-gray-900 whitespace-nowrap">
                                {item.name}
                                <AiOutlineEdit
                                  data-tooltip-id="editItem"
                                  data-tooltip-content="Edit this item."
                                  onClick={() => {
                                    setShowTooltip(false);
                                    setCurrentItem(item);
                                    setIsEditItemModalOpen(true);
                                  }}
                                  className="ml-2 inline-flex hover:cursor-pointer outline-none"
                                  onMouseEnter={() => setShowTooltip(true)}
                                />
                                <AiOutlineDelete
                                  data-tooltip-id="deleteItem"
                                  data-tooltip-content="Delete this item."
                                  onClick={async () => {
                                    setShowTooltip(false);
                                    setCurrentGroup(group);
                                    await handleDeleteItem(item.id);
                                  }}
                                  className="ml-2 inline-flex hover:cursor-pointer outline-none"
                                  onMouseEnter={() => setShowTooltip(true)}
                                />
                                {itemIndex > 0 && (
                                  <AiOutlineArrowUp
                                    data-tooltip-id="moveItemUp"
                                    data-tooltip-content="Move this item up a level."
                                    onClick={() => {
                                      setShowTooltip(false);
                                      handleMoveItem(item.id, MoveDirection.UP);
                                    }}
                                    className="ml-2 inline-flex hover:cursor-pointer outline-none"
                                    onMouseEnter={() => setShowTooltip(true)}
                                  />
                                )}
                                {itemIndex !== groupItems.length - 1 &&
                                  groupItems.length > 1 && (
                                    <AiOutlineArrowDown
                                      data-tooltip-id="moveItemDown"
                                      data-tooltip-content="Move this item down a level."
                                      onClick={() => {
                                        setShowTooltip(false);
                                        handleMoveItem(
                                          item.id,
                                          MoveDirection.DOWN
                                        );
                                      }}
                                      className="ml-2 inline-flex hover:cursor-pointer outline-none"
                                      onMouseEnter={() => setShowTooltip(true)}
                                    />
                                  )}
                                {showTooltip && <Tooltip id="editItem" />}
                                {showTooltip && <Tooltip id="deleteItem" />}
                                {showTooltip && <Tooltip id="moveItemUp" />}
                                {showTooltip && <Tooltip id="moveItemDown" />}
                              </td>
                              <td className="px-6 py-4 text-green-500 text-center">
                                {item.type === ItemType.CR
                                  ? `$${convertFromAnnualToBudgetFrequency(
                                      convertToAnnualFromBudgetFrequency(
                                        item.amount,
                                        item.frequency
                                      ),
                                      budgetFrequency
                                    ).toFixed(2)}`
                                  : ''}
                              </td>
                              <td className="px-6 py-4 text-red-500 text-center">
                                {item.type === ItemType.DR
                                  ? `$${convertFromAnnualToBudgetFrequency(
                                      convertToAnnualFromBudgetFrequency(
                                        item.amount,
                                        item.frequency
                                      ),
                                      budgetFrequency
                                    ).toFixed(2)}`
                                  : ''}
                              </td>
                              {group.items.indexOf(item) ===
                                group.items.length - 1 && (
                                <td className="px-6 py-4 font-bold text-center">
                                  {formattedGroupTotal}
                                </td> // Only show total on final item.
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    );
                  })}
                  <BudgetSummary amountRemaining={amountRemaining} />
                </table>
              </div>
              <CreateGroupModal
                open={isCreateGroupModalOpen}
                onClose={() => {
                  setCurrentGroup(null);
                  setIsCreateGroupModalOpen(false);
                }}
                onSubmit={async (group: Omit<ItemGroup, 'id' | 'items'>) => {
                  const updatedBudget = createGroup(budget, group);
                  setCurrentGroup(null);
                  setIsCreateGroupModalOpen(false);
                  setBudget(await updateBudget(updatedBudget));
                }}
              />
              <CreateItemModal
                open={isCreateItemModalOpen}
                onClose={() => {
                  setCurrentItem(null);
                  setIsCreateItemModalOpen(false);
                }}
                onSubmit={async (item: Omit<BudgetItem, 'id'>) => {
                  const updatedBudget = createItem(
                    budget,
                    currentGroup.id,
                    item
                  );
                  setCurrentItem(null);
                  setIsCreateItemModalOpen(false);
                  setBudget(await updateBudget(updatedBudget));
                }}
              />
              <EditGroupModal
                open={isEditGroupModalOpen}
                group={currentGroup}
                onClose={() => {
                  setCurrentGroup(null);
                  setIsEditGroupModalOpen(false);
                }}
                onSubmit={async (group: ItemGroup) => {
                  const updatedBudget = updateGroup(
                    budget,
                    currentGroup.id,
                    group
                  );
                  setCurrentGroup(null);
                  setIsEditGroupModalOpen(false);
                  setBudget(await updateBudget(updatedBudget));
                }}
              />
              <EditItemModal
                open={isEditItemModalOpen}
                item={currentItem}
                onClose={() => {
                  setCurrentItem(null);
                  setIsEditItemModalOpen(false);
                }}
                onSubmit={async (item: BudgetItem) => {
                  const updatedBudget = updateItem(
                    budget,
                    currentItem.id,
                    item
                  );
                  setCurrentItem(null);
                  setIsEditItemModalOpen(false);
                  setBudget(await updateBudget(updatedBudget));
                }}
              />
              <BudgetSettingsModal
                budget={budget}
                open={isBudgetSettingsModalOpen}
                onClose={() => {
                  setIsBudgetSettingsModalOpen(false);
                }}
                onSubmit={async (updates: Partial<BudgetDefinition>) => {
                  setIsBudgetSettingsModalOpen(false);
                  setBudget(await updateBudget({ ...budget, ...updates }));
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
