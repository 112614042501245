import { CognitoUser } from '@aws-amplify/auth';
import { Auth, Hub } from 'aws-amplify';
import { createContext, useEffect, useState } from 'react';
import { ConfirmSignUpInput, SignInInput, SignUpInput } from '../types';

interface AuthState {
  user: CognitoUser | null;
  signIn(input: SignInInput): Promise<void>;
  signOut(): Promise<void>;
  signUp(input: SignUpInput): Promise<void>;
  confirmSignUp(input: ConfirmSignUpInput): Promise<void>;
}

const AuthContext = createContext<AuthState>({
  user: null,
  signIn: async () => {},
  signOut: async () => {},
  signUp: async () => {},
  confirmSignUp: async () => {},
});

export const AuthProvider = ({ children }) => {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthState => {
  const [user, setUser] = useState<CognitoUser>(null);

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          setUser(data);
          break;
        case 'signOut':
          setUser(null);
          break;
        default:
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then(currentUser => setUser(currentUser))
      .catch(() => console.warn('Not signed in'));

    return unsubscribe;
  }, []);

  const signIn = async ({ email, password }: SignInInput) => {
    const user = await Auth.signIn(email, password);
    setUser(user);
  };

  const signOut = async () => {
    await Auth.signOut();
    setUser(null);
  };

  const signUp = async ({ email, password, countryCode }: SignUpInput) => {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        'custom:countryCode': countryCode,
      },
      autoSignIn: {
        enabled: true,
      },
    });
  };

  const confirmSignUp = async ({ email, code }: ConfirmSignUpInput) => {
    await Auth.confirmSignUp(email, code);
  };

  return {
    // @ts-ignore: https://github.com/aws-amplify/amplify-js/issues/4927
    user: user?.attributes,
    signIn,
    signOut,
    signUp,
    confirmSignUp,
  };
};
