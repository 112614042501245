import { useEffect, useState } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { BUDGET_FREQUENCIES } from '../../config';
import {
  BudgetDefinition,
  BudgetItem,
  ItemType,
  TimePeriod,
} from '../../types';
import { calculateRemaining } from '../../utils/calculateRemaining';
import { convertFromAnnualToBudgetFrequency } from '../../utils/convertFromAnnualToBudgetFrequency';
import { convertToAnnualFromBudgetFrequency } from '../../utils/convertToAnnualFromBudgetFrequency';
import { getGroupTotal } from '../../utils/getGroupTotal';
import { LoadingSpinner } from '../LoadingSpinner';
import { BaseItemGroupSummary } from './BaseItemGroupSummary';
import { BudgetSummary } from './BudgetSummary';
import { useBudgets } from './hooks/useBudgets';

export const Budget = () => {
  const navigate = useNavigate();
  const { getBudget } = useBudgets();
  const { budgetId } = useParams();
  const [budget, setBudget] = useState<BudgetDefinition>(null);
  const [amountRemaining, setAmountRemaining] = useState(0);
  const [budgetFrequency, setBudgetFrequency] = useState(
    TimePeriod.FORTNIGHTLY // How I get paid.
  );

  useEffect(() => {
    async function startFetching() {
      setBudget(null);
      const result = await getBudget(Number(budgetId));
      if (!ignore) {
        setBudget(result);
      }
    }

    let ignore = false;
    startFetching();
    return () => {
      ignore = true;
    };
  }, [budgetId]);

  useEffect(() => {
    setAmountRemaining(calculateRemaining(budget, budgetFrequency));
  }, [budget, budgetFrequency]);

  return (
    <div className="m-auto flex flex-col max-w-screen-lg items-center justify-center pt-12 pb-24 px-6">
      <div className="flex flex-col w-full items-center">
        <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full">
          {budget == null ? (
            <LoadingSpinner />
          ) : (
            <div className="text-center">
              <span className="float-left font-bold text-4xl">
                {budget?.budgetName}
              </span>
              <div className="flex float-right pb-4">
                <Select
                  isSearchable={false}
                  placeholder={TimePeriod.FORTNIGHTLY}
                  name="budgetFrequency"
                  onChange={({ value }) => setBudgetFrequency(value)}
                  className="m-2 w-44 align-middle text-center"
                  options={BUDGET_FREQUENCIES}
                />
                <button
                  onClick={() => navigate(`/budget/edit/${budgetId}`)}
                  className="m-2 float-right p-4 flex items-center align-middle focus:outline-none text-white text-sm sm:text-base bg-gray-800 hover:bg-gray-700 rounded py-2 transition duration-150 ease-in"
                >
                  <span>
                    Edit Budget
                    <AiOutlineEdit className="ml-2 inline-flex" />
                  </span>
                </button>
              </div>
              <div className="mt-4 w-full relative overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 text-center">
                    <tr>
                      <th scope="col" className="px-6 py-3" />
                      <th scope="col" className="px-6 py-3">
                        CR
                      </th>
                      <th scope="col" className="px-6 py-3">
                        DR
                      </th>
                      <th scope="col" className="px-6 py-3">
                        SUBTOTAL
                      </th>
                    </tr>
                  </thead>
                  <BaseItemGroupSummary
                    budget={budget}
                    budgetFrequency={budgetFrequency}
                  />
                  {budget?.itemGroups.map(group => {
                    const groupTotal = convertFromAnnualToBudgetFrequency(
                      getGroupTotal(group.items),
                      budgetFrequency
                    );

                    // Ensure '-' comes before '$'.
                    const formattedGroupTotal =
                      groupTotal > 0
                        ? `$${groupTotal.toFixed(2)}`
                        : `-$${Math.abs(groupTotal).toFixed(2)}`;

                    return (
                      <tbody key={group.id}>
                        <tr>
                          <td className="pt-8 pb-6 text-gray-900 whitespace-nowrap font-medium">
                            {group?.name}
                          </td>
                        </tr>
                        {group.items.map((item: BudgetItem) => (
                          <tr key={item.id} className="bg-white border-y">
                            <td className="pl-10 px-6 py-4 text-gray-900 whitespace-nowrap">
                              {item.name}
                            </td>
                            <td className="px-6 py-4 text-green-500 text-center">
                              {item.type === ItemType.CR
                                ? `$${convertFromAnnualToBudgetFrequency(
                                    convertToAnnualFromBudgetFrequency(
                                      item.amount,
                                      item.frequency
                                    ),
                                    budgetFrequency
                                  ).toFixed(2)}`
                                : ''}
                            </td>
                            <td className="px-6 py-4 text-red-500 text-center">
                              {item.type === ItemType.DR
                                ? `$${convertFromAnnualToBudgetFrequency(
                                    convertToAnnualFromBudgetFrequency(
                                      item.amount,
                                      item.frequency
                                    ),
                                    budgetFrequency
                                  ).toFixed(2)}`
                                : ''}
                            </td>
                            {group.items.indexOf(item) ===
                              group.items.length - 1 && (
                              <td className="px-6 py-4 font-bold text-center">
                                {formattedGroupTotal}
                              </td> // Only show total on final item.
                            )}
                          </tr>
                        ))}
                      </tbody>
                    );
                  })}
                  <BudgetSummary amountRemaining={amountRemaining} />
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
