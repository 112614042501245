import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { AiFillQuestionCircle } from 'react-icons/ai';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import { KIWISAVER_PERCENTAGES } from '../../config';
import { BudgetDefinition } from '../../types';
import { LoadingSpinner } from '../LoadingSpinner';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface BudgetSettingsModalProps {
  budget?: BudgetDefinition;
  open: boolean;
  onClose: () => void;
  onSubmit: (updates: Partial<BudgetDefinition>) => void;
}

export const BudgetSettingsModal = ({
  budget,
  open,
  onClose,
  onSubmit,
}: BudgetSettingsModalProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    setErrorMessage('');

    try {
      setIsLoading(true);
      const formData = new FormData(event.target);

      const budgetName = String(formData.get('budgetName'));
      const hasStudentLoan = Boolean(formData.get('hasStudentLoan'));
      const salarySacrifice = Boolean(formData.get('salarySacrifice'));
      const fundContributionRate = Number(formData.get('fundContributionRate'));

      // Strip out non-numeric characters.
      const annualSalary = Number(
        formData.get('annualSalary').toString().split('$')[1].replace(',', '')
      );

      onSubmit({
        budgetName,
        annualSalary,
        hasStudentLoan,
        salarySacrifice,
        fundContributionRate,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(`Failed to create budget: ${error}`);
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="mt-2">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col mb-6">
                <div className="relative">
                  <span>Name</span>
                  <input
                    id="budgetName"
                    type="text"
                    name="budgetName"
                    className="mt-2 text-sm text-center sm:text-base placeholder-gray-500 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                    placeholder="My Budget"
                    defaultValue={budget?.budgetName}
                    required
                  />
                </div>
                <div className="relative mt-4">
                  <span>Salary (annual)</span>
                  <CurrencyInput
                    id="annualSalary"
                    name="annualSalary"
                    placeholder="$60000.00"
                    defaultValue={budget?.annualSalary}
                    required
                    decimalsLimit={2}
                    prefix="$"
                    className="mt-2 text-sm text-center sm:text-base placeholder-gray-500 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                  />
                </div>
                <div className="relative mt-4">
                  <span>Kiwisaver contribution</span>
                  <sup className="ml-1">(excluding employer match)</sup>
                  <Select
                    name="fundContributionRate"
                    placeholder="0%"
                    defaultValue={
                      budget != null &&
                      KIWISAVER_PERCENTAGES.find(
                        x => x.value === String(budget.fundContributionRate)
                      )
                    }
                    className="mt-2 text-center"
                    options={KIWISAVER_PERCENTAGES}
                  />
                </div>
                <div className="relative mt-6 inline-flex">
                  <span className="pt-1">Salary Sacrifice </span>
                  <span className="ml-2">
                    <AiFillQuestionCircle
                      data-tooltip-id="salarySacrifice"
                      data-tooltip-content="Select this if your employer deducts their Kiwisaver contribution from your salary."
                    />
                  </span>
                  <input
                    type="checkbox"
                    name="salarySacrifice"
                    defaultChecked={budget?.salarySacrifice}
                    className="ml-4 h-8 w-8"
                  />
                  <Tooltip id="salarySacrifice" />
                </div>
                <div className="relative mt-6 inline-flex">
                  <span className="pt-1">I have a student loan</span>
                  <input
                    type="checkbox"
                    defaultChecked={budget?.hasStudentLoan}
                    name="hasStudentLoan"
                    className="ml-4 h-8 w-8"
                  />
                </div>
              </div>
              <div className="flex w-full">
                <button
                  type="button"
                  onClick={onClose}
                  className="mt-4 flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-gray-800 hover:bg-gray-700 rounded py-2 w-full transition duration-150 ease-in"
                >
                  <span>Cancel</span>
                </button>
                <button
                  type="submit"
                  className="ml-4 mt-4 flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-gray-800 hover:bg-gray-700 rounded py-2 w-full transition duration-150 ease-in"
                >
                  <span>{budget == null ? 'Create' : 'Update'} budget</span>
                </button>
              </div>
            </form>
            <span className="mt-4 text-red-500 font-bold flex justify-center">
              {errorMessage}
            </span>
          </div>
        )}
      </div>
    </Dialog>
  );
};
