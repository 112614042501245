import { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { AiFillQuestionCircle } from 'react-icons/ai';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import { KIWISAVER_PERCENTAGES } from '../config';
import { calculateTakehomePay } from '../utils/calculateTakehomePay';

export const PayCalculator = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [calculationResult, setCalculationResult] = useState(0);

  const handleSubmit = async event => {
    event.preventDefault();
    setCalculationResult(0);
    setErrorMessage('');

    try {
      const formData = new FormData(event.target);

      const nDays = Number(formData.get('nDays'));
      const hasStudentLoan = Boolean(formData.get('hasStudentLoan'));
      const salarySacrifice = Boolean(formData.get('salarySacrifice'));
      const kiwisaverPercentage = Number(formData.get('kiwisaverPercentage'));

      // Strip out non-numeric characters.
      const annualSalary = Number(
        formData.get('annualSalary').toString().split('$')[1].replace(',', '')
      );

      const result =
        (calculateTakehomePay({
          annualSalary,
          kiwisaverPercentage,
          salarySacrifice,
          haveStudentLoan: hasStudentLoan,
        }).netIncome /
          365) *
        nDays;

      setCalculationResult(Number(result.toFixed(2)));
    } catch (error) {
      setErrorMessage(`Calculation failed: ${error}`);
    }
  };

  return (
    <div className="flex flex-col w-full items-center justify-center pt-12 pb-24 px-6">
      <div className="flex flex-col w-full items-center">
        <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
          <span className="mb-4">
            Leaving your job? Calculate your final payout
          </span>
          <div className="mt-2">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col mb-6">
                <div className="relative">
                  <span>Annual leave remaining (days)</span>
                  <input
                    id="nDays"
                    type="number"
                    name="nDays"
                    className="mt-2 text-sm text-center sm:text-base placeholder-gray-500 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                    placeholder="14"
                    required
                  />
                </div>
                <div className="relative mt-4">
                  <span>Salary (annual)</span>
                  <CurrencyInput
                    id="annualSalary"
                    name="annualSalary"
                    placeholder="$60000"
                    required
                    decimalsLimit={2}
                    prefix="$"
                    className="mt-2 text-sm text-center sm:text-base placeholder-gray-500 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                  />
                </div>
                <div className="relative mt-4">
                  <span>Kiwisaver contribution</span>
                  <sup className="ml-1">(excluding employer match)</sup>
                  <Select
                    name="kiwisaverPercentage"
                    placeholder="0%"
                    className="mt-2 text-center"
                    options={KIWISAVER_PERCENTAGES}
                  />
                </div>
                <div className="relative mt-6 inline-flex">
                  <span className="pt-1">Salary Sacrifice </span>
                  <span className="ml-2">
                    <AiFillQuestionCircle
                      data-tooltip-id="salarySacrifice"
                      data-tooltip-content="Select this if your employer deducts their Kiwisaver contribution from your salary."
                    />
                  </span>
                  <input
                    type="checkbox"
                    name="salarySacrifice"
                    className="ml-4 h-8 w-8"
                  />
                  <Tooltip id="salarySacrifice" />
                </div>
                <div className="relative mt-6 inline-flex">
                  <span className="pt-1">I have a student loan</span>
                  <input
                    type="checkbox"
                    name="hasStudentLoan"
                    className="ml-4 h-8 w-8"
                  />
                </div>
              </div>
              <div className="flex w-full">
                <button
                  type="submit"
                  className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-gray-800 hover:bg-gray-700 rounded py-2 w-full transition duration-150 ease-in"
                >
                  <span className="mr-2">Show me the money 💸</span>
                </button>
              </div>
            </form>
            <div className="flex justify-center items-center mt-4 mb-4"></div>
            {calculationResult ? (
              <span className="mt-4 text-green-500 font-bold flex justify-center">
                Your payout will be approximately ${calculationResult}
                {errorMessage}
              </span>
            ) : null}
            <span className="mt-4 text-red-500 font-bold flex justify-center">
              {errorMessage}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
