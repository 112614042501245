// Each array defines a tax bracket. First two elements in each array are the floor and ceiling of the given tax brackets, final element is the tax rate as a fraction. All income equal to or above $180001 is taxed at 39%.
// See Income Tax Brackets here:
// https://www.moneyhub.co.nz/paye-tax-rates.html
const PAYE_TAX_TABLE_2023 = [
  [0, 14000, 0.105],
  [14001, 48000, 0.18],
  [48001, 70000, 0.3],
  [70001, 180000, 0.33],
  [180001, 0, 0.39],
];

export const calculateTax = (annualSalary: number) => {
  let amtAlreadyTaxed = 0; // Used as a running total for calculations.
  let totalTaxAmt = 0; // What we actually care about.

  for (const [floor, ceiling, taxRate] of PAYE_TAX_TABLE_2023) {
    if (annualSalary >= floor) {
      if (annualSalary >= ceiling && ceiling !== 0) {
        amtAlreadyTaxed += ceiling - floor;
        totalTaxAmt += (ceiling - floor) * taxRate;
        continue;
      }

      if (annualSalary < ceiling || ceiling === 0) {
        const remaining = annualSalary - amtAlreadyTaxed;
        totalTaxAmt += remaining * taxRate;
      }
    }
  }

  return totalTaxAmt;
};
