import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { ItemGroup } from '../../../types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface CreateGroupModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (group: Omit<ItemGroup, 'id' | 'items'>) => Promise<void>;
}

export const CreateGroupModal = ({
  open,
  onClose,
  onSubmit,
}: CreateGroupModalProps) => {
  const handleSubmit = event => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const name = String(formData.get('name'));
    onSubmit({ name });
    event.target.reset();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="px-8 py-8 flex flex-col w-full items-center">
        <span className="text-center font-bold">Add a new group</span>
        <div className="mt-2">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col mb-6">
              <div className="relative">
                <span>Name</span>
                <input
                  id="name"
                  type="text"
                  name="name"
                  className="mt-2 text-sm text-center sm:text-base placeholder-gray-500 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                  placeholder="My Group"
                  required
                />
              </div>
            </div>
            <div className="flex w-full">
              <button
                type="button"
                onClick={onClose}
                className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-gray-600 hover:bg-gray-700 rounded py-2 w-full transition duration-150 ease-in mr-2"
              >
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-gray-800 hover:bg-gray-700 rounded p-2 w-full transition duration-150 ease-in"
              >
                <span>Add group</span>
              </button>
            </div>
            <div className="flex justify-center items-center mt-4 mb-4"></div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};
