import { v4 as uuidV4 } from 'uuid';

import { BudgetDefinition, ItemGroup, ItemType, TimePeriod } from '../../types';
import {
  calculateTakehomePay,
  CalculateTakehomePayResult,
} from '../../utils/calculateTakehomePay';
import { convertFromAnnualToBudgetFrequency } from '../../utils/convertFromAnnualToBudgetFrequency';
import { getGroupTotal } from '../../utils/getGroupTotal';
import { sortByItemType } from '../../utils/sortByItemType';

const buildBaseGroup = (
  annualSalary: number,
  args: CalculateTakehomePayResult
): ItemGroup => {
  const baseItems = [
    {
      id: uuidV4(),
      name: 'Income',
      amount: annualSalary,
      type: ItemType.CR,
      frequency: TimePeriod.YEARLY,
    },
    {
      id: uuidV4(),
      name: 'PAYE',
      amount: args.payeAmt,
      type: ItemType.DR,
      frequency: TimePeriod.YEARLY,
    },
    {
      id: uuidV4(),
      name: 'ACC',
      amount: args.accAmt,
      type: ItemType.DR,
      frequency: TimePeriod.YEARLY,
    },
  ];

  if (args.kiwisaverAmt > 0) {
    baseItems.push({
      id: uuidV4(),
      name: 'Kiwisaver',
      amount: args.kiwisaverAmt,
      type: ItemType.DR,
      frequency: TimePeriod.YEARLY,
    });
  }

  if (args.studentLoanRepaymentAmt > 0) {
    baseItems.push({
      id: uuidV4(),
      name: 'Student Loan',
      amount: args.studentLoanRepaymentAmt,
      type: ItemType.DR,
      frequency: TimePeriod.YEARLY,
    });
  }

  return {
    id: uuidV4(),
    name: 'Income and Duties',
    items: baseItems,
  };
};

interface BaseItemGroupSummaryProps {
  budget: BudgetDefinition;
  budgetFrequency: TimePeriod;
}

export const BaseItemGroupSummary = ({
  budget,
  budgetFrequency,
}: BaseItemGroupSummaryProps) => {
  if (budget == null) {
    return null;
  }

  const convertedAnnualSalary = convertFromAnnualToBudgetFrequency(
    budget.annualSalary,
    budgetFrequency
  );

  const baseGroup = buildBaseGroup(
    convertedAnnualSalary,
    calculateTakehomePay({
      annualSalary: budget.annualSalary,
      kiwisaverPercentage: budget.fundContributionRate,
      haveStudentLoan: budget.hasStudentLoan,
      salarySacrifice: budget.salarySacrifice,
      budgetFrequency: budgetFrequency,
    })
  );

  return (
    <tbody key={baseGroup.id}>
      <tr>
        <td className="pt-8 pb-6 text-gray-900 whitespace-nowrap font-medium">
          {baseGroup?.name}
        </td>
      </tr>
      {baseGroup.items.sort(sortByItemType).map(item => {
        const groupTotal = getGroupTotal(baseGroup.items);

        return (
          <tr key={item.id} className="bg-white border-y">
            <td className="pl-10 px-6 py-4 text-gray-900 whitespace-nowrap ">
              {item.name}
            </td>
            <td className="px-6 py-4 text-green-500 text-center">
              {item.type === ItemType.CR ? `$${item.amount.toFixed(2)}` : ''}
            </td>
            <td className="px-6 py-4 text-red-500 text-center">
              {item.type === ItemType.DR ? `$${item.amount.toFixed(2)}` : ''}
            </td>
            {baseGroup.items.indexOf(item) === baseGroup.items.length - 1 && (
              <td className="px-6 py-4 font-bold text-center">
                ${groupTotal.toFixed(2)}
              </td> // Only show total on final item.
            )}
          </tr>
        );
      })}
    </tbody>
  );
};
